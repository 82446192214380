import type { FormContainerNode } from "@/types/node.ts";
import Fields from "@/components/Form/Fields/Fields.tsx";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";

type ContainerProps = FormContainerNode & {
  gridCol: number;
};

const Container = ({
  nodes,
  visibleIf,
  visibleIfOp,
  gridCol,
}: ContainerProps) => {
  const { isVisible } = useFieldRules({ visibleIf, visibleIfOp });

  if (!isVisible) return null;

  return nodes?.map((node) => (
    <Fields key={node.name} node={node} recursiveGridCol={gridCol} />
  ));
};

export default Container;
