// https://github.com/tailwindlabs/tailwindcss/discussions/8272#discussioncomment-3618508

type GenerateSpanProps = {
  breakpoint: "span" | "sm" | "md" | "lg" | "xl";
  cols: number;
};

const generateSpan = ({ breakpoint, cols }: GenerateSpanProps) => {
  switch (breakpoint) {
    case "span": {
      switch (cols) {
        case 1:
          return "fr-col-span-1";
        case 2:
          return "fr-col-span-2";
        case 3:
          return "fr-col-span-3";
        case 4:
          return "fr-col-span-4";
        case 5:
          return "fr-col-span-5";
        case 6:
          return "fr-col-span-6";
        case 7:
          return "fr-col-span-7";
        case 8:
          return "fr-col-span-8";
        case 9:
          return "fr-col-span-9";
        case 10:
          return "fr-col-span-10";
        case 11:
          return "fr-col-span-11";
        default:
          return "fr-col-span-12";
      }
    }
    case "sm": {
      switch (cols) {
        case 1:
          return "max-sm:fr-col-span-1";
        case 2:
          return "max-sm:fr-col-span-2";
        case 3:
          return "max-sm:fr-col-span-3";
        case 4:
          return "max-sm:fr-col-span-4";
        case 5:
          return "max-sm:fr-col-span-5";
        case 6:
          return "max-sm:fr-col-span-6";
        case 7:
          return "max-sm:fr-col-span-7";
        case 8:
          return "max-sm:fr-col-span-8";
        case 9:
          return "max-sm:fr-col-span-9";
        case 10:
          return "max-sm:fr-col-span-10";
        case 11:
          return "max-sm:fr-col-span-11";
        default:
          return "max-sm:fr-col-span-12";
      }
    }
    case "md": {
      switch (cols) {
        case 1:
          return "max-md:fr-col-span-1";
        case 2:
          return "max-md:fr-col-span-2";
        case 3:
          return "max-md:fr-col-span-3";
        case 4:
          return "max-md:fr-col-span-4";
        case 5:
          return "max-md:fr-col-span-5";
        case 6:
          return "max-md:fr-col-span-6";
        case 7:
          return "max-md:fr-col-span-7";
        case 8:
          return "max-md:fr-col-span-8";
        case 9:
          return "max-md:fr-col-span-9";
        case 10:
          return "max-md:fr-col-span-10";
        case 11:
          return "max-md:fr-col-span-11";
        default:
          return "max-md:fr-col-span-12";
      }
    }
    case "lg": {
      switch (cols) {
        case 1:
          return "max-lg:fr-col-span-1";
        case 2:
          return "max-lg:fr-col-span-2";
        case 3:
          return "max-lg:fr-col-span-3";
        case 4:
          return "max-lg:fr-col-span-4";
        case 5:
          return "max-lg:fr-col-span-5";
        case 6:
          return "max-lg:fr-col-span-6";
        case 7:
          return "max-lg:fr-col-span-7";
        case 8:
          return "max-lg:fr-col-span-8";
        case 9:
          return "max-lg:fr-col-span-9";
        case 10:
          return "max-lg:fr-col-span-10";
        case 11:
          return "max-lg:fr-col-span-11";
        default:
          return "max-lg:fr-col-span-12";
      }
    }
    case "xl": {
      switch (cols) {
        case 1:
          return "max-xl:fr-col-span-1";
        case 2:
          return "max-xl:fr-col-span-2";
        case 3:
          return "max-xl:fr-col-span-3";
        case 4:
          return "max-xl:fr-col-span-4";
        case 5:
          return "max-xl:fr-col-span-5";
        case 6:
          return "max-xl:fr-col-span-6";
        case 7:
          return "max-xl:fr-col-span-7";
        case 8:
          return "max-xl:fr-col-span-8";
        case 9:
          return "max-xl:fr-col-span-9";
        case 10:
          return "max-xl:fr-col-span-10";
        case 11:
          return "max-xl:fr-col-span-11";
        default:
          return "max-xl:fr-col-span-12";
      }
    }
  }
};

export { generateSpan };
