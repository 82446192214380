import * as React from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import { cn } from "@/lib/utils";

const Popover = PopoverPrimitive.Root;

const PopoverTrigger = PopoverPrimitive.Trigger;

const PopoverContent = React.forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      className={cn(
        "fr-w-[--radix-popover-trigger-width] fr-max-h-[--radix-popover-content-available-height] fr-z-50 fr-rounded-md fr-border fr-bg-popover fr-text-popover-foreground fr-shadow-md fr-outline-none data-[state=open]:fr-animate-in data-[state=closed]:fr-animate-out data-[state=closed]:fr-fade-out-0 data-[state=open]:fr-fade-in-0 data-[state=closed]:fr-zoom-out-95 data-[state=open]:fr-zoom-in-95 data-[side=bottom]:fr-slide-in-from-top-2 data-[side=left]:fr-slide-in-from-right-2 data-[side=right]:fr-slide-in-from-left-2 data-[side=top]:fr-slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverTrigger, PopoverContent };
