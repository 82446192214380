interface MarkdownProps {
  content: string;
}

const Markdown = ({ content }: MarkdownProps) => {
  return (
    <div
      // biome-ignore lint/security/noDangerouslySetInnerHtml: markdown content
      dangerouslySetInnerHTML={{ __html: content }}
      className="fr-contents fr-prose prose-p:fr-m-0 prose-h2:fr-m-0 prose-headings:fr-font-semibold prose-p:fr-margin-block-reset prose-a:fr-text-blue-600"
    />
  );
};

export default Markdown;
