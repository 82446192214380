import { getTokenData } from "@/api";
import {
  extractAllRulesFromSchema,
  findFormRendererScript,
  getDefaultValuesFromUISchema,
} from "@/lib/utils";

type GetUiSchemaProps = {
  token?: string | null;
  formId: string | null;
};

export async function getUiSchemaAndPrepareData({
  token,
  formId,
}: GetUiSchemaProps) {
  try {
    const script = findFormRendererScript();

    const response = await fetch(
      `${import.meta.env.VITE_FORM_EDITOR_API_URL}/ui-form-schema/${script?.[1] ?? formId}`,
    );
    const formMetadata = await response.json();

    const defaultValues = getDefaultValuesFromUISchema(formMetadata.schema);
    const rules = extractAllRulesFromSchema(formMetadata.schema);
    const tokenData =
      token && (await getTokenData({ product: formMetadata.product, token }));

    const phoneCode = tokenData?.data?.phoneCode?.split(":");

    return {
      uiSchema: formMetadata.schema,
      defaultValues: {
        ...defaultValues,
        ...(token && tokenData),
        ...(phoneCode?.[0] && {
          phoneNumber: `+${phoneCode?.[0]} ${tokenData?.data?.phoneNumber}`,
        }),
        ...(phoneCode?.[1] && { phoneCode: phoneCode?.[1] }),
      },
      rules,
      product: formMetadata.product,
      ...(tokenData?.currentPage && {
        currentStep: tokenData?.currentPage,
      }),
    };
  } catch (error) {
    console.error("Failed to fetch form data:", error);
    throw error;
  }
}
