import { createContext, type ReactNode, useContext, useState } from "react";
import { useSearchParamsContext } from "@/providers";

export type ApplicantToken = string | null;

type ApplicantTokenContextProps = {
  applicantToken?: ApplicantToken;
  setApplicantToken?: (applicantToken: ApplicantToken) => void;
};

export const ApplicantTokenContext = createContext<ApplicantTokenContextProps>(
  {},
);

export const useApplicantContext = () => useContext(ApplicantTokenContext);

type ApplicantTokenProps = {
  children: ReactNode;
};

export const ApplicantTokenProvider = ({ children }: ApplicantTokenProps) => {
  const { token } = useSearchParamsContext();
  const [applicantToken, setApplicantToken] = useState<ApplicantToken>(
    token ?? null,
  );

  return (
    <ApplicantTokenContext.Provider
      value={{ applicantToken, setApplicantToken }}
    >
      {children}
    </ApplicantTokenContext.Provider>
  );
};
