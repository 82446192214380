import { headers } from "@/constants.ts";

type ResponseData =
  | { key: string; value: string; code: string }[]
  | Record<number, string>
  | Record<number, Record<number, string>>;

type FetchCountriesListProps = {
  product: string;
};

const prepareOptions = (data: ResponseData, specialCase?: string) => {
  if (!data) return [];

  if (specialCase) {
    const output: { [key: string]: { label: string; value: string }[] } = {};

    for (const category of Object.keys(data)) {
      output[category] = Object.keys(data[category as any]).map(
        (subCategory) => ({
          // @ts-ignore
          label: data[category][subCategory],
          value: subCategory,
        }),
      );
    }

    return output;
  }

  if (Array.isArray(data)) {
    return data.map((d: any) => ({
      label: d.value,
      value: d.key,
      ...(d.code && {
        code: d.code.toUpperCase(),
      }),
    }));
  }

  return Object.entries(data)?.map(([key, value]) => ({
    label: value,
    value: key,
  }));
};

export async function fetchFormData({ product }: FetchCountriesListProps) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${product}/data/schema`,
      {
        headers,
      },
    );

    const schema = await response.json();
    const countries = prepareOptions(schema?.COUNTRY_CODES);
    const birthCountries = prepareOptions(schema?.BIRTH_COUNTRIES);
    const territories = prepareOptions(schema?.TERRITORIES);
    const nationalities = prepareOptions(schema?.NATIONALITIES);
    const jobOccupations = prepareOptions(schema?.jobOccupations);
    const jobTitles = prepareOptions(schema?.jobTitles, "jobTitles");
    const identityVerificationQuestions = prepareOptions(
      schema?.IDENTITY_VERIFICATION_QUESTIONS,
    );
    const securityQuestionsConditions = prepareOptions(
      schema?.SECURITY_QUESTIONS_CONDITIONS,
    );
    const maritalStatuses = prepareOptions(schema?.maritalStatuses);
    const passportTypes = prepareOptions(schema?.passportTypes);
    const travelTypes = prepareOptions(schema?.travelTypes);
    const genders = prepareOptions(schema?.gender);
    const timezones = prepareOptions(schema?.timeZones);

    return {
      countries,
      birthCountries,
      territories,
      identityVerificationQuestions,
      nationalities,
      securityQuestionsConditions,
      jobOccupations,
      jobTitles,
      maritalStatuses,
      passportTypes,
      travelTypes,
      genders,
      timezones,
    };
  } catch (error) {
    console.error("Failed to fetch form data:", error);
    throw error;
  }
}
