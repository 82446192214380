import { createContext, type ReactNode, useContext, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { getUiSchemaAndPrepareData } from "@/api/fetchUISchema/fetchUiSchema.ts";
import { fetchFormData } from "@/api";
import { useSearchParamsContext } from "@/providers";
import type { Form } from "@/types/form.ts";
import type { FormRule } from "@/types/rule.ts";
import { FormProvider } from "@/components/Form/Form";
import { useForm } from "react-hook-form";
import { differenceInYears } from "date-fns";

type FormMetadataContextType = {
  isLoading: boolean;
  isError?: any;
  uiSchema?: Form;
  defaultValues?: Record<string, unknown>;
  product?: string;
  rules?: {
    selector: string;
    rules: FormRule[];
  }[];
  currentStep: number;
};

export const FormMetadataContext = createContext<FormMetadataContextType>({
  isLoading: true,
  currentStep: 0,
});

export const useFormMetadataContext = () => useContext(FormMetadataContext);

type FormMetadataProviderProps = {
  children: ReactNode;
  form?: any;
};

export const FormMetadataProvider = ({
  children,
  form,
}: FormMetadataProviderProps) => {
  const { token, formId } = useSearchParamsContext();

  const methods = useForm({
    mode: "onBlur",
  });

  const {
    data,
    isLoading: isLoadingEditorFormMetadata,
    isError,
  } = useQuery({
    queryKey: ["uiSchema"],
    queryFn: async () => {
      const data = await getUiSchemaAndPrepareData({ token, formId });
      // https://www.react-hook-form.com/api/useform/#resetOptions
      methods.reset(data?.defaultValues);
      return data;
    },
    enabled: !form?.uiSchema,
  });

  const { isLoading: isLoadingFormData } = useQuery({
    queryKey: ["formData"],
    queryFn: () => fetchFormData({ product: data?.product }),
    enabled: !!data?.product,
  });

  const isAdult =
    differenceInYears(+new Date(), +new Date(methods.getValues("birthDate"))) >=
    18;

  const dataUiSchema = {
    ...data?.uiSchema,
    ...(isAdult
      ? { pages: data?.uiSchema?.pages }
      : {
          pages: data?.uiSchema?.pages?.filter(
            (step: any) => !step?.visibleIf || step?.visibleIf?.length === 0,
          ),
        }),
  };

  const formUiSchema = {
    ...form?.uiSchema,
    ...(isAdult
      ? { pages: form?.uiSchema?.pages }
      : {
          pages: form?.uiSchema?.pages?.filter(
            (step: any) => !step?.visibleIf || step?.visibleIf?.length === 0,
          ),
        }),
  };

  const isLoading = isLoadingEditorFormMetadata || isLoadingFormData;
  const uiSchema = dataUiSchema || formUiSchema;
  const defaultValues = data?.defaultValues || form?.defaultValues;
  const rules = data?.rules || form?.rules;
  const product = data?.product || form?.product;
  const currentStep = data?.currentStep || 0;

  return (
    <FormMetadataContext.Provider
      value={{
        isError,
        isLoading,
        uiSchema,
        product,
        defaultValues,
        rules,
        currentStep,
      }}
    >
      <FormProvider {...methods}>{children}</FormProvider>
    </FormMetadataContext.Provider>
  );
};
