import { subDays, subMonths } from "date-fns";
import type { FormRule } from "@/types/rule.ts";

type MaxDateRuleProps = {
  rule: FormRule | any;
  value: Date;
  formValues: Record<string, any>;
  fieldName?: string;
};

const maxDateRule = ({
  rule,
  value,
  formValues,
  fieldName,
}: MaxDateRuleProps) => {
  const genericErrorMessage = "Invalid date value";

  if (
    !value ||
    !(new Date(value) instanceof Date) ||
    Number.isNaN(new Date(value).getTime())
  ) {
    return genericErrorMessage;
  }

  if (!rule?.value) {
    return "Invalid rule value";
  }

  if (rule.value.type === "currentDate") {
    const ruleValue = rule.value?.offset?.value;
    const unit = rule.value?.offset?.unit;

    if (ruleValue === undefined || !unit) {
      return "Invalid offset";
    }

    const today = new Date();

    let maxDate: any;
    if (unit === "day") {
      maxDate = subDays(today, Math.abs(ruleValue));
    } else if (unit === "month") {
      maxDate = subMonths(today, Math.abs(ruleValue));
    }

    return +new Date(value) <= +maxDate || rule.message;
  }

  if (rule.value.type === "field") {
    const referenceDate = formValues[rule.value.nodeName || fieldName];

    if (!referenceDate) {
      return "Referenced field date is missing";
    }

    return +new Date(value) <= +new Date(referenceDate) || rule.message;
  }

  return true;
};

export { maxDateRule };
