import type { FormPage } from "@/types/page.ts";

type GetFieldNamesProps = {
  steps: FormPage[];
  currentStep: number;
};

function getFieldNames({ steps, currentStep }: GetFieldNamesProps): string[] {
  const currentPage = steps[currentStep];
  if (!currentPage) {
    return [];
  }

  return (currentPage.nodes || [])
    .filter((c: any) => c.type === "container")
    .reduce((fields: string[], field: any) => {
      return [
        ...fields,
        ...((field.nodes || []) as { name: string }[]).map((f) => f.name),
      ];
    }, []);
}

export { getFieldNames };
