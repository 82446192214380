import { parse } from "tldts";
import type { ApplicantToken } from "@/providers/ApplicantTokenProvider/ApplicantTokenProvider.tsx";
import { headers } from "@/constants.ts";

type CreateTemporaryApplicant = {
  email: string;
  userCountry?: string;
  adPixel?: string;
  adPixel2?: string;
  product: string;
};

export async function createTemporaryApplicant({
  email,
  userCountry,
  adPixel = "",
  adPixel2 = "",
  product,
}: CreateTemporaryApplicant): Promise<ApplicantToken> {
  const parsedSite = parse(
    import.meta.env.VITE_ENV === "dev"
      ? `https://visa.govassist.com/application/${product}`
      : window.location.href,
  );
  const userLanguage = document.documentElement.lang;

  const additionalSiteData = {
    adPixel,
    adPixel2,
    siteId: parsedSite?.hostname ?? "govassist.com",
    affiliateId: "",
    userCountry,
    userLanguage,
  };

  const emailKey = product === "eta" ? "userEmail" : "email";

  const payload = JSON.stringify({
    additionalSiteData,
    [emailKey]: email,
  });

  try {
    const res = await fetch(
      `${import.meta.env.VITE_API_URL}/${product}/temporary`,
      {
        method: "POST",
        body: payload,
        headers,
      },
    ).then((r) => r.json());

    return res.token;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
