import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import type { FormDateNode } from "@/types/node.ts";
import { useApplicantMutations, useConditionalRendering } from "@/hooks";
import { GridCol } from "@/components/Grid/Col.tsx";
import { shapeRulesForReactHookForm } from "@/lib/utils";
import { DatePicker } from "@/components/DatePicker/DatePicker";
import { useApplicantContext } from "@/providers";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";

type HandleChangeProps = {
  value?: string;
  onChange: (value?: string) => void;
};

const DateField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormDateNode & { gridCol?: number }) => {
  const { control } = useFormContext();
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();
  const { validationRules, isVisible, defaultValue } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });

  if (!isVisible) return null;

  const handleChange = ({ value, onChange }: HandleChangeProps) => {
    onChange(value);

    if (applicantToken) {
      updateTemporaryApplicantMutation?.mutate({
        field: name,
        value,
      });
    }
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <DatePicker
                placeholder={placeholder}
                {...field}
                defaultValue={defaultValue}
                onChange={(value) =>
                  handleChange({ value, onChange: field.onChange })
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default DateField;
