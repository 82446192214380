import * as React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { X } from "lucide-react";

import { cn } from "@/lib/utils";

const Dialog = DialogPrimitive.Root;

const DialogTrigger = DialogPrimitive.Trigger;

const DialogPortal = DialogPrimitive.Portal;

const DialogClose = DialogPrimitive.Close;

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fr-fixed fr-inset-0 fr-z-50 fr-bg-black/80 data-[state=open]:fr-animate-in data-[state=closed]:fr-animate-out data-[state=closed]:fr-fade-out-0 data-[state=open]:fr-fade-in-0",
      className,
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogPrimitive.Content
      ref={ref}
      className={cn(
        "fr-fixed fr-left-[50%] fr-top-[50%] fr-z-50 fr-grid fr-w-full fr-max-w-lg fr-translate-x-[-50%] fr-translate-y-[-50%] fr-gap-4 fr-border fr-bg-background fr-p-6 fr-shadow-lg fr-duration-200 data-[state=open]:fr-animate-in data-[state=closed]:fr-animate-out data-[state=closed]:fr-fade-out-0 data-[state=open]:fr-fade-in-0 data-[state=closed]:fr-zoom-out-95 data-[state=open]:fr-zoom-in-95 data-[state=closed]:fr-slide-out-to-left-1/2 data-[state=closed]:fr-slide-out-to-top-[48%] data-[state=open]:fr-slide-in-from-left-1/2 data-[state=open]:fr-slide-in-from-top-[48%] sm:fr-rounded-lg",
        className,
      )}
      {...props}
    >
      {children}
      <DialogPrimitive.Close className="fr-absolute fr-right-4 fr-top-4 fr-rounded-sm fr-opacity-70 fr-ring-offset-background fr-transition-opacity hover:fr-opacity-100 focus:fr-outline-none focus:fr-ring-2 focus:fr-ring-ring focus:fr-ring-offset-2 disabled:fr-pointer-events-none data-[state=open]:fr-bg-accent data-[state=open]:fr-text-muted-foreground">
        <X className="fr-h-4 fr-w-4" />
        <span className="fr-sr-only">Close</span>
      </DialogPrimitive.Close>
    </DialogPrimitive.Content>
  </DialogPortal>
));
DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "fr-flex fr-flex-col fr-space-y-1.5 fr-text-center sm:fr-text-left",
      className,
    )}
    {...props}
  />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "fr-flex fr-flex-col-reverse sm:fr-flex-row sm:fr-justify-end sm:fr-space-x-2",
      className,
    )}
    {...props}
  />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    ref={ref}
    className={cn(
      "fr-text-lg fr-font-semibold fr-leading-none fr-tracking-tight",
      className,
    )}
    {...props}
  />
));
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    className={cn("fr-text-sm fr-text-muted-foreground", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
};
