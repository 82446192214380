import { parse } from "tldts";
import { headers } from "@/constants.ts";

type CreateTemporaryApplicant = {
  applicantToken: string;
  field?: string;
  fields?: Record<string, string>;
  value: any;
  userCountry: string;
  adPixel?: string;
  adPixel2?: string;
  getValues: any;
};

export async function updateTemporaryApplicant({
  applicantToken,
  field,
  fields,
  value,
  userCountry,
  adPixel = "",
  adPixel2 = "",
  getValues,
}: CreateTemporaryApplicant) {
  const parsedSite = parse("https://visa.govassist.com/application/eta");
  const userLanguage = document.documentElement.lang;

  const additionalSiteData = {
    adPixel,
    adPixel2,
    siteId: parsedSite?.hostname ?? "govassist.com",
    affiliateId: "",
    userCountry,
    userLanguage,
  };

  const payload = JSON.stringify({
    ...additionalSiteData,
    ...getValues?.(),
    ...(field && { [field]: value }),
    ...(fields && fields),
  });

  try {
    await fetch(
      `${import.meta.env.VITE_API_URL}/eta/temporary/${applicantToken}`,
      {
        method: "PATCH",
        body: payload,
        headers,
      },
    );
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}
