import { FormField } from "@/components/Form/Form.tsx";
import type { FormAlertNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { Alert } from "@/components/Alert/Alert";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useEffect } from "react";

const AlertField = ({
  name,
  alertType,
  alertTitle,
  alertMessage,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
}: FormAlertNode & { gridCol?: number }) => {
  const { control, setError, clearErrors } = useFormContext();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage: alertMessage,
    isPassportCodeAndNationalityMismatch:
      name === "passportCodeAndNationalityMismatch",
  });

  useEffect(() => {
    if (alertType === "error") {
      setError(name, { type: "custom", message: alertMessage });
    }

    return () => clearErrors(name);
  }, [isVisible]);

  if (!isVisible) return null;

  return (
    <GridCol
      span={gridCol}
      sm={12}
      className={isVisible ? "" : "fr-display-none"}
    >
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={() => (
          <Alert type={alertType} title={alertTitle} message={alertMessage} />
        )}
      />
    </GridCol>
  );
};

export default AlertField;
