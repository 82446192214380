import * as React from "react";
import type { DialogProps } from "@radix-ui/react-dialog";
import { Command as CommandPrimitive } from "cmdk";
import { Search } from "lucide-react";

import { cn } from "@/lib/utils";
import { Dialog, DialogContent } from "@/components/Dialog/Dialog.tsx";

const Command = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive
    ref={ref}
    className={cn(
      "fr-flex fr-h-full fr-w-full fr-flex-col fr-overflow-hidden fr-rounded-md fr-bg-popover fr-text-popover-foreground",
      className,
    )}
    {...props}
  />
));
Command.displayName = CommandPrimitive.displayName;

interface CommandDialogProps extends DialogProps {}

const CommandDialog = ({ children, ...props }: CommandDialogProps) => {
  return (
    <Dialog {...props}>
      <DialogContent className="fr-overflow-hidden fr-p-0 fr-shadow-lg">
        <Command className="[&_[cmdk-group-heading]]:fr-px-2 [&_[cmdk-group-heading]]:fr-font-medium [&_[cmdk-group-heading]]:fr-text-muted-foreground [&_[cmdk-group]:not([hidden])_~[cmdk-group]]:fr-pt-0 [&_[cmdk-group]]:fr-px-2 [&_[cmdk-input-wrapper]_svg]:fr-h-5 [&_[cmdk-input-wrapper]_svg]:fr-w-5 [&_[cmdk-input]]:fr-h-12 [&_[cmdk-item]]:fr-px-2 [&_[cmdk-item]]:fr-py-3 [&_[cmdk-item]_svg]:fr-h-5 [&_[cmdk-item]_svg]:fr-w-5">
          {children}
        </Command>
      </DialogContent>
    </Dialog>
  );
};

const CommandInput = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Input>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  <div
    className="fr-flex fr-items-center fr-border-b fr-px-3"
    cmdk-input-wrapper=""
  >
    <Search className="fr-mr-2 fr-h-4 fr-w-4 fr-shrink-0 fr-opacity-50" />
    <CommandPrimitive.Input
      ref={ref}
      className={cn(
        "fr-flex fr-h-11 fr-w-full fr-rounded-md fr-bg-transparent fr-py-3 fr-text-sm fr-outline-none placeholder:fr-text-muted-foreground disabled:fr-cursor-not-allowed disabled:fr-opacity-50",
        className,
      )}
      {...props}
    />
  </div>
));

CommandInput.displayName = CommandPrimitive.Input.displayName;

const CommandList = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn(
      "fr-max-h-[300px] fr-overflow-y-auto fr-overflow-x-hidden",
      className,
    )}
    {...props}
  />
));

CommandList.displayName = CommandPrimitive.List.displayName;

const CommandEmpty = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Empty>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>((props, ref) => (
  <CommandPrimitive.Empty
    ref={ref}
    className="fr-py-6 fr-text-center fr-text-sm"
    {...props}
  />
));

CommandEmpty.displayName = CommandPrimitive.Empty.displayName;

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      "fr-overflow-hidden fr-p-1 fr-text-foreground [&_[cmdk-group-heading]]:fr-px-2 [&_[cmdk-group-heading]]:fr-py-1.5 [&_[cmdk-group-heading]]:fr-text-xs [&_[cmdk-group-heading]]:fr-font-medium [&_[cmdk-group-heading]]:fr-text-muted-foreground",
      className,
    )}
    {...props}
  />
));

CommandGroup.displayName = CommandPrimitive.Group.displayName;

const CommandSeparator = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Separator
    ref={ref}
    className={cn("fr--mx-1 fr-h-px fr-bg-border", className)}
    {...props}
  />
));
CommandSeparator.displayName = CommandPrimitive.Separator.displayName;

const CommandItem = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Item>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      "fr-relative fr-flex fr-cursor-default fr-select-none fr-items-center fr-rounded-sm fr-px-2 fr-py-1.5 fr-text-sm fr-outline-none data-[disabled=true]:fr-pointer-events-none data-[selected=true]:fr-bg-accent data-[selected=true]:fr-text-accent-foreground data-[disabled=true]:fr-opacity-50",
      className,
    )}
    {...props}
  />
));

CommandItem.displayName = CommandPrimitive.Item.displayName;

const CommandShortcut = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLSpanElement>) => {
  return (
    <span
      className={cn(
        "fr-ml-auto fr-text-xs fr-tracking-widest fr-text-muted-foreground",
        className,
      )}
      {...props}
    />
  );
};
CommandShortcut.displayName = "CommandShortcut";

export {
  Command,
  CommandDialog,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandShortcut,
  CommandSeparator,
};
