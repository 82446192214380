import { cn } from "@/lib/utils";
import { CircleAlert, CircleCheck, Info, TriangleAlert } from "lucide-react";
import type { ComponentProps } from "react";
import Markdown from "@/components/Markdown/Markdown.tsx";

type AlertProps = ComponentProps<"div"> & {
  type: "success" | "warning" | "error" | "note";
  title?: string;
  message?: string;
};

const Alert = ({ type, title, message, className, ...props }: AlertProps) => {
  return (
    <div
      {...props}
      className={cn(
        "fr-flex fr-flex-row fr-items-start fr-gap-2 fr-px-3 fr-py-2 fr-border fr-text-sm",
        {
          "fr-bg-blue-50 fr-border-blue-300 fr-text-blue-900": type === "note",
          "fr-bg-green-50 fr-border-green-300 fr-text-green-900":
            type === "success",
          "fr-bg-yellow-50 fr-border-yellow-300 fr-text-yellow-900":
            type === "warning",
          "fr-bg-red-50 fr-border-red-300 fr-text-red-900": type === "error",
        },
        className,
      )}
    >
      <div className="fr-flex fr-flex-col fr-items-center fr-justify-center fr-h-[20px]">
        {type === "note" && <Info size={16} />}
        {type === "success" && <CircleCheck size={16} />}
        {type === "warning" && <TriangleAlert size={16} />}
        {type === "error" && <CircleAlert size={16} />}
      </div>
      <div className="fr-flex fr-flex-col fr-flex-1 fr-gap-1">
        {title && <div className="fr-font-semibold">{title}</div>}
        {message && <Markdown content={message} />}
      </div>
    </div>
  );
};

export { Alert };
