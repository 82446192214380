import { createContext, type ReactNode, useContext } from "react";

type SearchParamConextType = {
  token: string | null;
  formId: string | null;
  coupon: string | null;
};

export const SearchParamContext = createContext<SearchParamConextType>({
  token: null,
  formId: null,
  coupon: null,
});

export const useSearchParamsContext = () => useContext(SearchParamContext);

type SearchParamProviderProps = {
  children: ReactNode;
};

export const SearchParamsProvider = ({
  children,
}: SearchParamProviderProps) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const coupon = urlParams.get("coupon");
  // Local development - for prod see `src/api/fetchUiSchema.ts`
  const formId = urlParams.get("form");

  return (
    <SearchParamContext.Provider value={{ token, formId, coupon }}>
      {children}
    </SearchParamContext.Provider>
  );
};
