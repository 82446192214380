import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/lib/utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      "fr-aspect-square -fr-mr-1 fr-h-4 fr-w-4 fr-rounded-[4px] fr-border fr-border-input fr-text-primary fr-ring-offset-background focus:fr-outline-none focus-visible:ring-2 focus-visible:fr-ring-ring focus-visible:fr-ring-offset-2 disabled:fr-cursor-not-allowed disabled:fr-opacity-50 data-[state=checked]:fr-bg-light-blue-100",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        "fr-flex fr-items-center fr-justify-center fr-text-current",
      )}
    >
      <Check className="fr-h-4 fr-w-4 fr-stroke-[3px] fr-text-light-blue-300" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
