export async function getCloudflareJSON() {
  const data = await fetch("https://one.one.one.one/cdn-cgi/trace").then(
    (res) => res.text(),
  );
  const arr = data
    .trim()
    .split("\n")
    .map((e) => e.split("="));
  return Object.fromEntries(arr);
}

export async function getUserCountry() {
  const cf = await getCloudflareJSON();
  return cf?.loc ?? "US";
}
