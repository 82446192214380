import type { Form } from "@/types/form.ts";
import type { FormContainerNode, FormNode } from "@/types/node.ts";

function extractAllRulesFromSchema(schema: Pick<Form, "pages">): any {
  const extractedRules: any[] = [];

  function traverseNodes(nodes: FormNode[]) {
    for (const node of nodes) {
      if (node.rules) {
        extractedRules.push({ rules: node.rules, selector: node.name });
      }
      if (node.type === "container" && (node as FormContainerNode).nodes) {
        traverseNodes((node as FormContainerNode).nodes);
      }
    }
  }

  for (const page of schema.pages) {
    if (page.nodes) {
      traverseNodes(page.nodes);
    }
  }

  return extractedRules;
}

export { extractAllRulesFromSchema };
