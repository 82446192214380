import { headers } from "@/constants.ts";

type ValidateEmailProps = {
  email: string;
};

export async function validateEmail({ email }: ValidateEmailProps) {
  if (email.length === 0) return;
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/email-validation?ApiKey=${import.meta.env.VITE_SENDGRID_API_KEY}`,
      {
        method: "POST",
        body: JSON.stringify({ email }),
        headers,
      },
    );

    const obj = await response.json();

    if (Object.keys(obj).length === 0) {
      return {
        // biome-ignore lint/complexity/noUselessTernary: need sendgrid backend mock or vpn for dev/local testing
        isValid: import.meta.env.VITE_ENV === "dev" ? true : false,
      };
    }

    if (obj?.suggestion) {
      return {
        isValid: false,
        suggestion: obj.suggestion,
      };
    }

    return {
      isValid: true,
    };
  } catch (e) {
    return {
      isValid: false,
    };
  }
}
