/**
 * -- for backwards compatibility --
 * In laravel we are loading js scripts in this format:
 * https://form-renderer.forms.govassist.com/bundle.js?formId={{ config('forms.<product>) }}
 *
 * That's why we grab the form id from the script src
 */

const findFormRendererScript = () => {
  const formRendererScriptTag = Array.from(
    document.querySelectorAll("script"),
  ).find((s) => {
    const scriptToSearchFor = "form-renderer.forms.govassist.com";
    const formRendererScript = s
      .getAttribute("src")
      ?.includes(scriptToSearchFor);

    if (!formRendererScript) return false;

    return s;
  });

  const script = formRendererScriptTag?.getAttribute("src")?.split("formId=");

  if (!script) return undefined;
  return script;
};

export { findFormRendererScript };
