import type { FormRule } from "@/types/rule.ts";
import { useConditionalRendering } from "@/hooks";
import { shapeRulesForReactHookForm } from "@/lib/utils";
import { type RefObject, useEffect } from "react";
import type { FormBaseNode } from "@/types/node.ts";

type UseFieldRulesProps = {
  fieldName?: FormBaseNode["name"];
  fieldType?: FormBaseNode["type"];
  rules?: FormRule[];
  visibleIf: FormBaseNode["visibleIf"];
  visibleIfOp: FormBaseNode["visibleIfOp"];
  emptyMessage?: string;
  // Ref is needed only when we want to disable "paste" functionality
  ref?: RefObject<HTMLInputElement>;
  isPassportCodeAndNationalityMismatch?: boolean;
};

export function useFieldRules({
  fieldName,
  fieldType,
  rules,
  visibleIf,
  visibleIfOp,
  emptyMessage,
  ref,
  isPassportCodeAndNationalityMismatch,
}: UseFieldRulesProps) {
  const { isVisible } = useConditionalRendering({
    visibleIf,
    visibleIfOp,
    isPassportCodeAndNationalityMismatch,
  });
  const { validationRules, isCopyPasteDisabled, transform, defaultValue } =
    shapeRulesForReactHookForm({ fieldName, fieldType, rules, emptyMessage });

  useEffect(() => {
    if (isCopyPasteDisabled && ref?.current) {
      ref.current.onpaste = (e) => e.preventDefault();
    }
  }, [ref?.current]);

  return {
    isVisible,
    validationRules,
    transform,
    defaultValue,
  };
}
