type CheckPassportProps = {
  product: string;
  passportNumber: string;
};

/**
 * Checks if the passport already exists in etaapi db
 */
export async function checkPassport({
  product,
  passportNumber,
}: CheckPassportProps) {
  try {
    const res = await fetch(
      `${import.meta.env.VITE_API_URL}/${product}/applicant/exist-application?passportNumber=${passportNumber}&lifespan=15`,
      {
        method: "GET",
        headers: {
          "x-form-builder-version": "1.0",
        },
      },
    ).then((r) => r.json());

    return res;
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
}
