import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Switch } from "@/components/Switch/Switch.tsx";
import type { FormSwitchNode } from "@/types/node.ts";
import { useApplicantMutations, useConditionalRendering } from "@/hooks";
import { GridCol } from "@/components/Grid/Col.tsx";
import { shapeRulesForReactHookForm } from "@/lib/utils";
import { useFormContext } from "react-hook-form";
import { useApplicantContext } from "@/providers";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";

type HandleChangeProps = {
  name: string;
  value: boolean;
  onChange: (value: boolean) => void;
};

const SwitchField = ({
  name,
  label,
  description,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  tooltip,
}: FormSwitchNode & { gridCol?: number }) => {
  const { clearErrors, control } = useFormContext();
  const { validationRules, isVisible, defaultValue } = useFieldRules({
    fieldName: name,
    fieldType: "switch",
    rules,
    visibleIf,
    visibleIfOp,
  });
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();

  if (!isVisible) return null;

  const handleChange = ({ name, value, onChange }: HandleChangeProps) => {
    if (applicantToken) {
      updateTemporaryApplicantMutation.mutate({
        field: name,
        value,
      });
    }

    onChange(value);
    clearErrors(name);
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Switch
                checked={field.value}
                onCheckedChange={(value) =>
                  handleChange({ name, value, onChange: field.onChange })
                }
                value={field.value}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default SwitchField;
