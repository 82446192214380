import type { FormSelectNode } from "@/types/node.ts";
import Flag from "@/components/Flag/Flag.tsx";
import * as React from "react";

type ButtonLabel = {
  field: any;
  defaultValue: any;
  fieldOptions: { code?: string; value: string; label: string }[];
  placeholder: FormSelectNode["placeholder"];
};

const ButtonLabel = ({
  field,
  defaultValue,
  fieldOptions,
  placeholder,
}: ButtonLabel) => {
  const option = fieldOptions.find((option: any) =>
    field?.value
      ? option.value.toString() === field.value.toString()
      : option.value.toString() === defaultValue?.toString(),
  );

  // defaultValue can be 0 which in js means false, hence .toString()
  if (field?.value || defaultValue?.toString()) {
    if (option?.code) {
      return (
        <div className="fr-flex fr-space-x-2">
          <Flag alt={option.label} countryCode={option.code} />
          <p>{option.label}</p>
        </div>
      );
    }

    return option?.label || defaultValue;
  }

  return placeholder;
};

export default ButtonLabel;
