import * as React from "react";
import { memo } from "react";

type FlagProps = {
  alt: string;
  countryCode: string;
};

const Flag = ({ alt, countryCode }: FlagProps) => {
  return (
    <img
      className="fr-w-4"
      alt={alt}
      onError={() =>
        "http://purecatamphetamine.github.io/country-flag-icons/3x2/AQ.svg"
      }
      src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode ?? "AQ"}.svg`}
    />
  );
};

export default memo(Flag);
