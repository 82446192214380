import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import type { FormInputNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { PhoneInput } from "@/components/PhoneInput/PhoneInput.tsx";
import {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  getCountryCallingCode,
  isPossiblePhoneNumber,
} from "react-phone-number-input";
import { useApplicantMutations } from "@/hooks";
import { useApplicantContext } from "@/providers";
import { type FocusEvent, useState } from "react";

type HandleChangeProps = {
  value: any;
  onChange: (value: any) => void;
};

const PhoneInputField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  tooltip,
  emptyMessage,
}: FormInputNode & { gridCol?: number }) => {
  const { control, getValues, setError, clearErrors } = useFormContext();
  const [countryCode, setCountryCode] = useState(
    getValues("phoneCode")?.split?.(":")?.[1] ?? "",
  );
  const [isPossible, setIsPossible] = useState(
    isPossiblePhoneNumber(getValues("phoneNumber")),
  );
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });
  if (!isVisible) return null;

  const handleInputChange = ({ onChange, value }: HandleChangeProps) => {
    onChange(value);
    const isPhoneNumberPossible = isPossiblePhoneNumber(value, countryCode);
    setIsPossible(isPhoneNumberPossible);
  };

  const handleInputBlur = (e: FocusEvent<HTMLInputElement>) => {
    const callingCode = getCountryCallingCode(countryCode);
    if (!callingCode) return;
    if (applicantToken && e.target.value && countryCode) {
      if (!isPossible) {
        setError(name, {
          type: "custom",
          message: "Enter a valid phone number",
        });
      }

      if (isPossible) {
        clearErrors(name);
        const phoneNumber = e.target.value.replaceAll(" ", "");
        updateTemporaryApplicantMutation?.mutate({
          fields: {
            phoneNumber,
            phoneCode:
              `${callingCode}:${countryCode}` || getValues("phoneCode"),
          },
        });
      }
    }
  };

  const handleCountryChange = (countryCode: any) => {
    if (!countryCode) return;
    setCountryCode(countryCode);
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        control={control}
        name={name}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <PhoneInput
                placeholder={placeholder}
                defaultCountry={countryCode}
                {...field}
                onBlur={handleInputBlur}
                onChange={(value) =>
                  handleInputChange({ onChange: field.onChange, value })
                }
                onCountryChange={(value) => handleCountryChange(value)}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default PhoneInputField;
