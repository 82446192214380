"use client";

import { cn } from "@/lib/utils";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { DayPicker, getDefaultClassNames } from "react-day-picker";
import "react-day-picker/style.css";
import { buttonVariants } from "@/components/Button/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/Select/Select.tsx";
import type { ChangeEvent } from "react";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const defaultClassNames = getDefaultClassNames();
  return (
    <DayPicker
      showOutsideDays={false}
      classNames={{
        nav: "fr-absolute fr-w-full",
        months: "fr-relative",
        button_previous: `${defaultClassNames.button_previous} focus:fr-outline-none`,
        button_next: `${defaultClassNames.button_previous} fr-absolute fr-right-0 focus:fr-outline-none`,
        month_caption: `${defaultClassNames.month_caption} fr-justify-center fr-font-medium fr-text-sm`,
        weekday: `${defaultClassNames.weekday} fr-font-bold`,
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "fr-h-9 fr-w-9 fr-p-0 fr-font-normal fr-aria-selected:fr-opacity-100",
        ),
        today: `${defaultClassNames.today} fr-font-bold fr-text-blue-500`,
        selected: "fr-bg-gray-50 fr-border-blue-500 fr-border-2 ",
        ...classNames,
      }}
      components={{
        Dropdown: ({ value, onChange, options }) => (
          <Select
            value={value as string}
            onValueChange={(v) => {
              const e = {
                target: {
                  value: v,
                },
              };
              onChange?.(e as ChangeEvent<HTMLSelectElement>);
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue />
            </SelectTrigger>
            <SelectContent side="bottom" align="center">
              {options?.map((o) => (
                <SelectItem key={o.label} value={o.value as unknown as string}>
                  {o.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        ),
        Chevron: ({ orientation }) => {
          if (orientation === "left")
            return <ChevronLeft size={16} className="fr-mt-2 fr-ml-4" />;
          if (orientation === "right")
            return <ChevronRight size={16} className="fr-mt-2 fr-mr-4" />;
          return <p />;
        },
      }}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
