import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Input } from "@/components/Input/Input.tsx";
import type { FormInputNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useRef } from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";

type HandleChangeProps = {
  onChange: (value: string) => void;
};

const InputField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormInputNode & { gridCol?: number }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { control } = useFormContext();
  const { validationRules, isVisible, transform } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
    ref,
  });

  if (!isVisible) return null;

  const handleChange = ({ onChange }: HandleChangeProps) => {
    if (transform && ref?.current) {
      const { value } = ref.current;
      let transformedValue: any;
      switch (transform) {
        case "capitalize": {
          transformedValue = value.replace(
            value[0],
            value[0]?.toUpperCase() || "",
          );
          break;
        }
        case "uppercase": {
          transformedValue = value.replace(value, value?.toUpperCase() || "");
          break;
        }
      }

      return onChange(transformedValue || "");
    }
    onChange(ref?.current?.value || "");
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Input
                placeholder={placeholder}
                {...field}
                ref={ref}
                onChange={() => handleChange({ onChange: field.onChange })}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default InputField;
