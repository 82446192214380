import type * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const badgeVariants = cva(
  "fr-inline-flex fr-items-center fr-rounded-full fr-border fr-px-2.5 fr-py-0.5 fr-text-xs fr-font-semibold fr-transition-colors focus:fr-outline-none focus:fr-ring-2 focus:fr-ring-ring focus:fr-ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "fr-border-transparent fr-bg-primary fr-text-primary-foreground hover:fr-bg-primary/80",
        secondary:
          "fr-border-transparent fr-bg-secondary fr-text-secondary-foreground hover:fr-bg-secondary/80",
        destructive:
          "fr-border-transparent fr-bg-destructive fr-text-destructive-foreground hover:fr-bg-destructive/80",
        outline: "fr-text-foreground",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
