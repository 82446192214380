import type { FormRule } from "@/types/rule.ts";
import type { FormBaseNode } from "@/types/node.ts";
import { isBefore, isAfter, subDays, subMonths } from "date-fns";
import { useApplicantMutations } from "@/hooks";
import { useApplicantContext, useFormMetadataContext } from "@/providers";
import {
  maxDateRule,
  minDateRule,
} from "@/lib/utils/shapeRulesForReactHookForm/rules";

type ShapeRulesForReactHookFormProps = {
  fieldName?: FormBaseNode["name"];
  fieldType?: FormBaseNode["type"];
  rules?: FormRule[];
  emptyMessage?: string;
};

const getTypedValue = (value: any) => {
  if (!value.type) return value;

  switch (value.type) {
    case "string":
      return String(value.value);
    case "number":
      return Number(value.value);
  }
};

export const getRegExpForRuleType = (
  ruleType: FormRule["type"],
): { pattern: RegExp; message?: string } | null => {
  switch (ruleType) {
    case "ssNumber": {
      return {
        pattern: new RegExp(
          /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/gi,
        ),
      };
    }
    case "positiveNumber": {
      return {
        pattern: new RegExp(/^[+]?\d+([.]\d+)?$/gi),
      };
    }
    case "capitalize": {
      return {
        pattern: new RegExp(/^[A-Z].*/),
        message:
          "Passport number must start with a letter and it must be capitalized",
      };
    }
    case "uppercase": {
      return {
        pattern: new RegExp(/^[A-Z0-9\s]*$/),
        message: "Text must be in UPPERCASE format",
      };
    }
    // @ts-ignore
    case "firstCharacterLetter": {
      return {
        pattern: new RegExp(/^[a-zA-Z].*/),
      };
    }

    default: {
      return null;
    }
  }
};

const defaultRules = {
  isCopyPasteDisabled: false,
  transform: undefined,
  defaultValue: "",
  validationRules: {},
};

const shapeRulesForReactHookForm = ({
  fieldName,
  fieldType,
  rules,
  emptyMessage,
}: ShapeRulesForReactHookFormProps) => {
  const { product } = useFormMetadataContext();
  const { applicantToken } = useApplicantContext();
  const {
    validateEmailMutation,
    updateTemporaryApplicantMutation,
    createTemporaryApplicantMutation,
  } = useApplicantMutations();
  const message = emptyMessage ?? "Field should not be empty.";

  /**
   * This will be handled by form-editor in the future
   */
  const isTmpSignatureField = fieldName === "signature";
  if (isTmpSignatureField) {
    return {
      validationRules: {
        required: {
          value: true,
          message,
        },
        validate: {
          signatureMatch: (value: any, formValues: any) => {
            const input = value.toLowerCase();
            const firstLastNames =
              `${formValues?.firstName} ${formValues?.lastName}`.toLowerCase();

            return (
              input === firstLastNames ||
              "Please enter your full name, as displayed on your passport or travel document."
            );
          },
        },
      },
    };
  }

  if (!rules || rules?.length === 0) {
    if (fieldType === "switch") {
      return {};
    }

    return {
      validationRules: {
        required: {
          value: true,
          message,
        },
      },
    };
  }

  return rules.reduce((acc: any, rule: any) => {
    const isPatternRule = [
      "minDate",
      "maxDate",
      "ssNumber",
      "phoneNumber",
      "positiveNumber",
      "zipcode",
      "passport",
      "regexp",
      "firstCharacterLetter",
    ].includes(rule.type);

    const defaultValue =
      rule.type === "defaultValue" ? getTypedValue(rule.defaultValue) : null;
    const isCopyPasteDisabled = rule.type === "noCopyPaste";
    const transform = ["uppercase", "capitalize"].includes(rule.type)
      ? rule.type
      : false;

    const isEmailRule = rule.type === "email";
    const isFieldMatchRule = rule.type === "fieldMatch";
    const isMinDateRule = rule.type === "minDate";
    const isMaxDateRule = rule.type === "maxDate";

    return {
      ...acc,
      isCopyPasteDisabled: acc?.isCopyPasteDisabled || isCopyPasteDisabled,
      transform: acc?.transform || transform,
      defaultValue: acc?.defaultValue || defaultValue,
      validationRules: {
        ...acc?.validationRules,
        required: {
          value: true,
          message,
        },
        ...(rule?.value &&
          !isPatternRule && {
            [rule.type]: {
              value: getTypedValue(rule?.value),
              message: rule.message,
            },
          }),
        ...(isPatternRule && {
          pattern: {
            value: rule.regexp || getRegExpForRuleType(rule.type)?.pattern,
            message: rule.message || getRegExpForRuleType(rule.type)?.message,
          },
        }),
        validate: {
          ...acc.validationRules?.validate,
          ...(isEmailRule && {
            email: async (value: string) => {
              const emailRegex = new RegExp(
                /^[a-z0-9][\w\.]+\@\w+?(\.\w+){1,}$/gi,
              );
              const isValidViaRegex = emailRegex.test(value);
              if (isValidViaRegex) {
                const res = await validateEmailMutation.mutateAsync({
                  email: value,
                });

                if (res?.suggestion?.length > 0)
                  return `We detected a possible typo on your email. Suggestion: ${res?.suggestion}`;

                if (res?.isValid) {
                  if (!applicantToken) {
                    createTemporaryApplicantMutation.mutate({
                      email: value,
                      product: product as string,
                    });
                  }

                  if (applicantToken) {
                    updateTemporaryApplicantMutation?.mutate({
                      field: product === "eta" ? "userEmail" : "email",
                      value,
                    });
                  }

                  return true;
                }

                return rule.message;
              }

              return rule.message;
            },
          }),
          ...(isFieldMatchRule && {
            matchValueWithAnotherField: (value: any, formValues: any) => {
              return (
                value?.toString() ===
                  formValues[rule.nodeName || fieldName]?.toString() ||
                rule.message
              );
            },
          }),
          ...(isMinDateRule && {
            minDateRule: (value: Date, formValues: any) =>
              minDateRule({ rule, value, formValues, fieldName }),
          }),
          ...(isMaxDateRule && {
            maxDateRule: (value: Date, formValues: any) =>
              maxDateRule({ rule, value, formValues, fieldName }),
          }),
        },
      },
    };
  }, defaultRules);
};

export { shapeRulesForReactHookForm };
