/**
 * Key equals to the returned key defined in `src/api/fetchFormData.ts#5`
 * Value is a field name that should load the api data that is returned from `src/api/fetchFormData.ts`
 */
const queryToFieldMapping: Record<string, string[]> = {
  countries: [
    "country",
    "jobCountry",
    "addressCountry",
    "passportNationalityCode",
  ],
  nationalities: ["passportNationality", "nationalities"],
  birthCountries: ["countryOfBirth", "birthCountry"],
  territories: ["countryTerritory", "jobCountry"],
  genders: ["gender"],
  identityVerificationQuestions: ["identityVerificationQuestions"],
  jobOccupations: ["jobOccupation"],
  jobTitles: ["jobTitle"],
  maritalStatuses: ["maritalStatus"],
  passportTypes: ["passportType"],
  securityQuestionsConditions: ["securityQuestions", "backgroundCondition"],
  travelTypes: ["arrivalType"],
  timeZones: ["arrivalDateTimeZone"],
};

function getDataKeyByFieldName(fieldName: string) {
  for (const [key, values] of Object.entries(queryToFieldMapping)) {
    if (values.includes(fieldName)) {
      return key;
    }
  }
  return null;
}

export { getDataKeyByFieldName };
