import "./index.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { TooltipProvider } from "@/components/Tooltip/Tooltip.tsx";
import FormShell from "@/FormShell.tsx";
import {
  ApplicantTokenProvider,
  FormMetadataProvider,
  SearchParamsProvider,
} from "@/providers";

type FormRendererProps = {
  form?: any;
};

const hourStaleTime = 1000 * 60 * 60;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      staleTime: hourStaleTime,
    },
  },
});

const FormRenderer = ({ form }: FormRendererProps) => {
  return (
    <SearchParamsProvider>
      <QueryClientProvider client={queryClient}>
        <FormMetadataProvider form={form}>
          <ApplicantTokenProvider>
            <TooltipProvider>
              <FormShell />
            </TooltipProvider>
          </ApplicantTokenProvider>
        </FormMetadataProvider>
      </QueryClientProvider>
    </SearchParamsProvider>
  );
};

export default FormRenderer;
export { FormRenderer };
