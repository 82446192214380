import SelectField from "@/components/Form/Fields/SelectField/SelectField.tsx";
import type { FormSelectNode } from "@/types/node.ts";
import { useQuery } from "@tanstack/react-query";
import { getDataKeyByFieldName } from "@/lib/utils";
import { useFormContext } from "react-hook-form";

const JobTitleField = ({
  options,
  ...node
}: FormSelectNode & { gridCol?: number }) => {
  const { getValues } = useFormContext();
  const { data: etaapiFormSchemaData }: any = useQuery({
    queryKey: ["formData"],
    enabled: false,
  });
  const dataKey = getDataKeyByFieldName(node.name) ?? "";
  const selectedJobOccupation = getValues("jobOccupation");
  const jobTitlesBasedOnJobOccupation =
    etaapiFormSchemaData?.[dataKey][selectedJobOccupation || 0];

  // General conditional rendering is handled by SelectField
  if (!jobTitlesBasedOnJobOccupation) return null;

  return <SelectField {...node} options={jobTitlesBasedOnJobOccupation} />;
};

export default JobTitleField;
