import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "lucide-react";
import { forwardRef, useState, type ComponentProps } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "../Popover/Popover";
import { Calendar } from "../Calendar/Calendar";

export type DatePickerProps = Omit<
  ComponentProps<"div">,
  "onChange" | "onBlur"
> & {
  placeholder?: string;
  value: string;
  onChange?: (value?: string) => void;
  onBlur?: () => void;
};

export const DatePicker = forwardRef<HTMLInputElement, DatePickerProps>(
  (
    {
      value,
      defaultValue,
      placeholder,
      className,
      onChange,
      onBlur,
      ...props
    }: DatePickerProps,
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const dateValue = new Date(value ?? defaultValue);

    const handleChange = (value: Date) => {
      const valueToDate = new Date(value);
      const isoDateWithTZOffset = new Date(
        valueToDate.getTime() - valueToDate.getTimezoneOffset() * 60000,
      ).toISOString();
      onChange?.(isoDateWithTZOffset);
      setIsOpen(false);
      onBlur?.();
    };

    const handleOpenChange = (open: boolean) => {
      setIsOpen(open);
      if (!open) {
        onBlur?.();
      }
    };

    const isInvalidDate = Number.isNaN(dateValue.getTime());
    return (
      <Popover open={isOpen} onOpenChange={handleOpenChange}>
        <PopoverTrigger asChild>
          <div
            ref={ref}
            className={cn(
              "fr-cursor-pointer fr-px-3 fr-gap-2 fr-h-10 fr-rounded-md fr-border fr-border-input fr-flex fr-flex-row fr-items-center fr-text-sm",
              className,
            )}
            onClick={() => setIsOpen(true)}
          >
            <CalendarIcon size="16" className="fr-text-muted-foreground" />
            {!isInvalidDate && dateValue && (
              <span>{dateValue.toLocaleDateString()}</span>
            )}
            {(!dateValue || isInvalidDate) && placeholder && (
              <span className="fr-text-muted-foreground">{placeholder}</span>
            )}
          </div>
        </PopoverTrigger>
        <PopoverContent className="fr-w-auto fr-p-0" align="start">
          <Calendar
            {...props}
            required
            captionLayout="dropdown"
            mode="single"
            selected={dateValue}
            onSelect={handleChange}
          />
        </PopoverContent>
      </Popover>
    );
  },
);
