import type { FormCondition } from "@/types/condition.ts";

interface GetConditionValueProps {
  data: Record<string, any>;
  type: FormCondition["type"];
  selector: string;
  value?: string | [string, string]; // Adjusted for 'inRange'
  values?: any[];
}

function getConditionValue({
  data,
  type,
  selector,
  value,
  values,
}: GetConditionValueProps): boolean {
  const dataValue = data?.[selector];
  switch (type) {
    case "equal":
      return dataValue?.toString() === value?.toString();
    case "notEqual":
      return dataValue?.toString() !== value?.toString();
    case "lessThan":
      return Number(dataValue) < Number(value);
    case "greaterThan":
      return Number(dataValue) > Number(value);
    case "lessThanEqual":
      return Number(dataValue) <= Number(value);
    case "greaterThanEqual":
      return Number(dataValue) >= Number(value);
    case "inRange":
      if (Array.isArray(value) && value.length === 2) {
        const [minValue, maxValue] = value.map(Number);
        return Number(dataValue) >= minValue && Number(dataValue) <= maxValue;
      }
      return false;
    case "oneOf":
      return (
        values?.some((v) => dataValue?.toString() === v?.toString()) || false
      );
    case "noneOf":
      return !values?.every((v) => dataValue?.toString() === v?.toString());
    default:
      return false;
  }
}

export { getConditionValue };
