import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createTemporaryApplicant,
  getUserCountry,
  updateTemporaryApplicant,
  validateEmail,
} from "@/api";
import { useApplicantContext } from "@/providers";
import { useFormContext } from "react-hook-form";
import { checkPassport } from "@/api/checkPassport/checkPassport.ts";

export function useApplicantMutations() {
  const { setError, getValues } = useFormContext();
  const { applicantToken, setApplicantToken } = useApplicantContext();

  const { data: userCountry } = useQuery({
    queryKey: ["userCountry"],
    queryFn: getUserCountry,
  });

  const validateEmailMutation = useMutation({
    mutationKey: ["validateEmail"],
    mutationFn: validateEmail,
    onError: (error) => {
      console.log(error);
      setError("email", { type: "custom", message: error.message });
    },
  });

  const createTemporaryApplicantMutation = useMutation({
    mutationKey: ["createTemporaryApplicant"],
    mutationFn: createTemporaryApplicant,
    onSuccess: (token) => {
      setApplicantToken?.(token);
    },
  });

  const updateTemporaryApplicantMutation = useMutation({
    mutationKey: ["updateTemporaryApplicant"],
    mutationFn: (data: any) => {
      return updateTemporaryApplicant({
        ...data,
        applicantToken,
        userCountry,
        getValues,
      });
    },
  });

  const checkPassportExistenceInEtaapiMutation = useMutation({
    mutationKey: ["checkPassportExistenceInEtaapi"],
    mutationFn: checkPassport,
  });

  return {
    validateEmailMutation,
    createTemporaryApplicantMutation,
    updateTemporaryApplicantMutation,
    checkPassportExistenceInEtaapiMutation,
  };
}
