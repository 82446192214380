import { useQuery } from "@tanstack/react-query";
import { getDataKeyByFieldName } from "@/lib/utils";
import type { FormNode, FormSelectNode } from "@/types/node.ts";

type UseFieldOptionsProps = {
  name: FormNode["name"];
  options: FormSelectNode["options"];
};

export function useFieldOptions({ name, options }: UseFieldOptionsProps) {
  const { data: etaapiFormSchemaData }: any = useQuery({
    queryKey: ["formData"],
    enabled: false,
  });
  const dataKey = getDataKeyByFieldName(name) ?? "";

  const fieldOptions =
    options.length > 0 ? options : etaapiFormSchemaData?.[dataKey] ?? [];
  return { fieldOptions };
}
