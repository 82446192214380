import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import type { FormSelectNode } from "@/types/node.ts";
import {
  RadioGroup,
  RadioGroupItem,
} from "@/components/RadioGroup/RadioGroup.tsx";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useFieldOptions } from "@/hooks/useFieldOptions/useFieldOptions.ts";

type HandleChangeProps = {
  name: string;
  value: string;
  onChange: (value: string) => void;
};

const RadioGroupField = ({
  name,
  label,
  description,
  visibleIf,
  visibleIfOp,
  options,
  gridCol,
  rules,
  tooltip,
  emptyMessage,
}: FormSelectNode & { gridCol?: number }) => {
  const { control, clearErrors } = useFormContext();
  const { validationRules, isVisible, defaultValue } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });
  const { fieldOptions } = useFieldOptions({ name, options });

  if (!isVisible) return null;

  const handleChange = ({ name, value, onChange }: HandleChangeProps) => {
    onChange(value);
    clearErrors(name);
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        control={control}
        name={name}
        rules={validationRules}
        defaultValue={defaultValue}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <RadioGroup
                data-name={name}
                onValueChange={(value) =>
                  handleChange({ name, value, onChange: field.onChange })
                }
                className="fr-flex fr-flex-row fr-flex-wrap fr-gap-3"
              >
                {fieldOptions.map((option: any, i: number) => (
                  <FormItem
                    className="fr-flex fr-items-center fr-space-x-3 fr-space-y-0"
                    key={`${option.label}_${i}`}
                  >
                    <FormControl>
                      <RadioGroupItem
                        value={option.value}
                        data-name={name}
                        checked={option.value === field.value}
                      />
                    </FormControl>
                    <FormLabel className="fr-cursor-pointer fr-font-normal">
                      {option.label}
                    </FormLabel>
                  </FormItem>
                ))}
              </RadioGroup>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default RadioGroupField;
