import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { Check, ChevronDown, ChevronUp } from "lucide-react";

import { cn } from "@/lib/utils";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Trigger
    ref={ref}
    className={cn(
      "fr-flex fr-h-10 fr-w-full fr-items-center fr-justify-between fr-rounded-md fr-border fr-border-input fr-bg-background fr-px-3 fr-py-2 fr-text-sm placeholder:fr-text-muted-foreground focus:fr-outline-none disabled:fr-cursor-not-allowed disabled:fr-opacity-50 [&>span]:fr-line-clamp-1",
      className,
    )}
    {...props}
  >
    {children}
    <SelectPrimitive.Icon asChild>
      <ChevronDown className="fr-h-4 fr-w-4 fr-opacity-50" />
    </SelectPrimitive.Icon>
  </SelectPrimitive.Trigger>
));
SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      "fr-flex fr-cursor-default fr-items-center fr-justify-center fr-py-1",
      className,
    )}
    {...props}
  >
    <ChevronUp className="fr-h-4 fr-w-4" />
  </SelectPrimitive.ScrollUpButton>
));
SelectScrollUpButton.displayName = SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      "fr-flex fr-cursor-default fr-items-center fr-justify-center fr-py-1",
      className,
    )}
    {...props}
  >
    <ChevronDown className="fr-h-4 fr-w-4" />
  </SelectPrimitive.ScrollDownButton>
));
SelectScrollDownButton.displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "fr-relative fr-z-50 fr-max-h-96 fr-min-w-[8rem] fr-overflow-hidden fr-rounded-md fr-border fr-bg-popover fr-text-popover-foreground fr-shadow-md data-[state=open]:fr-animate-in data-[state=closed]:fr-animate-out data-[state=closed]:fr-fade-out-0 data-[state=open]:fr-fade-in-0 data-[state=closed]:fr-zoom-out-95 data-[state=open]:fr-zoom-in-95 data-[side=bottom]:fr-slide-in-from-top-2 data-[side=left]:fr-slide-in-from-right-2 data-[side=right]:fr-slide-in-from-left-2 data-[side=top]:fr-slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:fr-translate-y-1 data-[side=left]:-fr-translate-x-1 data-[side=right]:-fr-translate-x-1 data-[side=top]:-fr-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          "fr-p-1",
          position === "popper" &&
            "fr-h-[var(--radix-select-trigger-height)] fr-w-full fr-min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn(
      "fr-py-1.5 fr-pl-8 fr-pr-2 fr-text-sm fr-font-semibold",
      className,
    )}
    {...props}
  />
));
SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "fr-relative fr-flex fr-w-full fr-cursor-default fr-select-none fr-items-center fr-rounded-sm fr-py-1.5 fr-pl-8 fr-pr-2 fr-text-sm fr-outline-none focus:fr-bg-accent focus:fr-text-accent-foreground data-[disabled]:fr-pointer-events-none data-[disabled]:fr-opacity-50",
      className,
    )}
    {...props}
  >
    <span className="fr-absolute fr-left-2 fr-flex fr-h-3.5 fr-w-3.5 fr-items-center fr-justify-center">
      <SelectPrimitive.ItemIndicator>
        <Check className="fr-h-4 fr-w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
SelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-fr-mx-1 fr-my-1 fr-h-px fr-bg-muted", className)}
    {...props}
  />
));
SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
