import * as React from "react";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";

import { cn } from "@/lib/utils";

const TooltipProvider = TooltipPrimitive.Provider as any;

const Tooltip = TooltipPrimitive.Root;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "fr-max-w-[300px] fr-z-50 fr-overflow-hidden fr-rounded-md fr-border fr-bg-popover fr-px-3 fr-py-1.5 fr-text-sm fr-text-popover-foreground fr-shadow-md fr-animate-in fr-fade-in-0 fr-zoom-in-95 data-[state=closed]:fr-animate-out data-[state=closed]:fr-fade-out-0 data-[state=closed]:fr-zoom-out-95 data-[side=bottom]:fr-slide-in-from-top-2 data-[side=left]:fr-slide-in-from-right-2 data-[side=right]:fr-slide-in-from-left-2 data-[side=top]:fr-slide-in-from-bottom-2",
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider };
