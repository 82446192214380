import { headers } from "@/constants.ts";

type GetTokenDataProps = {
  product: string;
  token: string;
};

export async function getTokenData({ product, token }: GetTokenDataProps) {
  try {
    const response = await fetch(
      `${import.meta.env.VITE_API_URL}/${product}/temporary/${token}`,
      {
        headers,
      },
    ).then((r) => r?.json());

    return response.data;
  } catch (e) {
    console.error(e);
    return null;
  }
}
