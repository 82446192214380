"use client";
import type { ReactNode } from "react";
import { cn } from "@/lib/utils";

type GridProps = {
  children?: ReactNode;
  className?: string;
};

const Grid = ({ children, className, ...props }: GridProps) => {
  return (
    <div
      className={cn("fr-grid fr-grid-cols-12 fr-gap-4", className)}
      {...props}
    >
      {children}
    </div>
  );
};

export { Grid };
