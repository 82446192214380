import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root>
>(({ className, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      "fr-peer fr-inline-flex fr-h-[24px] fr-w-[40px] fr-shrink-0 fr-cursor-pointer fr-items-center fr-rounded-full fr-border-transparent fr-transition-colors focus-visible:fr-outline-none focus-visible:fr-ring-2 focus-visible:fr-ring-ring focus-visible:fr-ring-offset-2 focus-visible:fr-ring-offset-background disabled:fr-cursor-not-allowed disabled:fr-opacity-50 data-[state=checked]:fr-bg-success-400 fr-shadow-[inset_0_4px_4px_rgba(0,0,0,0.15)] data-[state=unchecked]:fr-bg-input",
      className,
    )}
    {...props}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "fr-pointer-events-none fr-block fr-h-[16px] fr-w-[16px] fr-rounded-full fr-bg-background fr-shadow-[0_4px_4px_rgba(0,0,0,0.15)] fr-ring-0 fr-transition-transform data-[state=checked]:fr-translate-x-[20px] data-[state=unchecked]:fr-translate-x-1",
      )}
    />
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
