import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";

const config = {
  apiKey: import.meta.env.VITE_HONEYBADGER_API_KEY,
  environment: "production",
  revision: "git SHA/project version",
  reportData: import.meta.env.VITE_ENV === "prd",
};

const honeybadger = Honeybadger.configure(config);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>
  </React.StrictMode>,
);
