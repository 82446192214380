import View from "@/View.tsx";
import { useFormMetadataContext } from "@/providers";

const FormShell = () => {
  const { isLoading, isError } = useFormMetadataContext();

  if (isError) return <p>Couldn't load form</p>;

  if (isLoading) return <p>loading</p>;

  return <View />;
};

export default FormShell;
