import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/Form/Form.tsx";
import type { FormInputNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useRef } from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { Checkbox } from "@/components/Checkbox/Checkbox.tsx";
import { useApplicantMutations } from "@/hooks";
import { useApplicantContext } from "@/providers";

type HandleChangeProps = {
  value: string | boolean;
  onChange: (value: string | boolean) => void;
};

const CheckboxField = ({
  name,
  label,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormInputNode & { gridCol?: number }) => {
  const ref = useRef<HTMLInputElement>(null);
  const { control } = useFormContext();
  const { applicantToken } = useApplicantContext();
  const { updateTemporaryApplicantMutation } = useApplicantMutations();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
    ref,
  });

  if (!isVisible) return null;

  const handleChange = ({ onChange, value }: HandleChangeProps) => {
    onChange(value);

    if (applicantToken) {
      updateTemporaryApplicantMutation.mutate({
        field: name,
        value,
      });
    }
  };

  return (
    <GridCol sm={6} md={3} lg={2} xl={3} span={2} className="fr-max-w-[150px]">
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field }) => (
          <FormItem
            withGap={false}
            className="fr-flex fr-flex-row fr-items-start fr-space-x-3 fr-rounded-md fr-border fr-p-4"
          >
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={(value) =>
                  handleChange({ onChange: field.onChange, value })
                }
              />
            </FormControl>
            <div className="fr-leading-none !fr-mt-0">
              <FormLabel tooltip={tooltip}>{label}</FormLabel>
            </div>
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default CheckboxField;
