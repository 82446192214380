import type { FormNode } from "@/types/node";
import Content from "@/components/Form/Content/Content";
import Container from "@/components/Form/Container/Container";
import DateField from "@/components/Form/Fields/DateField/DateField";
import InputField from "@/components/Form/Fields/InputField/InputField";
import SwitchField from "@/components/Form/Fields/SwitchField/SwitchField";
import RadioGroupField from "@/components/Form/Fields/RadioGroupField/RadioGroupField";
import SelectField from "@/components/Form/Fields/SelectField/SelectField";
import Alert from "@/components/Form/Alert/Alert";
import EmailField from "@/components/Form/Fields/EmailField/EmailField.tsx";
import MultiNationalitiesField from "@/components/Form/Fields/MultiNationalitiesField/MultiNationalitiesField.tsx";
import PhoneInputField from "@/components/Form/Fields/PhoneInputField/PhoneInputField.tsx";
import TimeField from "@/components/Form/Fields/TimeField/TimeField.tsx";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import CheckboxField from "@/components/Form/Fields/CheckboxField/CheckboxField.tsx";
import JobTitleField from "@/components/Form/Fields/JobTitleField/JobTitleField.tsx";

type FieldProps = {
  node: FormNode;
  gridCol?: number;
  recursiveGridCol?: number;
};

const Fields = ({ node, recursiveGridCol }: FieldProps) => {
  const gridCol = recursiveGridCol || 12 / (node as any)?.columns || 12;
  const { isVisible } = useFieldRules({
    visibleIf: node.visibleIf,
    visibleIfOp: node.visibleIfOp,
    isPassportCodeAndNationalityMismatch:
      node.name === "passportCodeAndNationalityMismatch",
  });

  if (!isVisible) return null;

  if (["userEmail", "email"].includes(node.name)) {
    // @ts-ignore
    return <EmailField key={node.name} {...node} gridCol={gridCol} />;
  }

  if (node.name === "nationalities") {
    return (
      // @ts-ignore
      <MultiNationalitiesField key={node.name} {...node} gridCol={gridCol} />
    );
  }

  if (node.name === "phoneNumber") {
    return (
      // @ts-ignore
      <PhoneInputField key={node.name} {...node} gridCol={gridCol} />
    );
  }

  if (node.name === "jobTitle") {
    return (
      // @ts-ignore
      <JobTitleField key={node.name} {...node} gridCol={gridCol} />
    );
  }

  switch (node.type) {
    case "content":
      return <Content {...node} gridCol={gridCol} />;
    case "container":
      return <Container {...node} gridCol={gridCol} />;
    case "alert":
      return <Alert key={node.name} {...node} gridCol={gridCol} />;
    case "input":
      return <InputField key={node.name} {...node} gridCol={gridCol} />;
    // @ts-ignore
    case "consent":
      // @ts-ignore
      return <CheckboxField key={node.name} {...node} gridCol={2} />;
    // @ts-ignore
    case "time":
      // @ts-ignore
      return <TimeField key={node.name} {...node} gridCol={gridCol} />;
    case "date":
      return <DateField key={node.name} {...node} gridCol={gridCol} />;
    case "switch":
      return <SwitchField key={node.name} {...node} gridCol={gridCol} />;
    case "select":
      if (node.variant === "radiogroup")
        return <RadioGroupField key={node.name} {...node} gridCol={gridCol} />;

      return <SelectField key={node.name} {...node} gridCol={gridCol} />;
    default:
      return null;
  }
};

export default Fields;
