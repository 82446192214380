import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/Form/Form.tsx";
import { Input } from "@/components/Input/Input.tsx";
import type { FormEmailNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import { useFormContext } from "react-hook-form";
import { useRef } from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";

type HandleBlurProps = {
  onBlur: (value: string) => void;
  value: string;
};

const EmailField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  gridCol,
  rules,
  emptyMessage,
  tooltip,
}: FormEmailNode & { gridCol?: number }) => {
  const { control } = useFormContext();
  const ref = useRef<HTMLInputElement>(null);
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
    ref,
  });

  if (!isVisible) return null;

  const handleBlur = ({ onBlur, value }: HandleBlurProps) => {
    onBlur(value);
  };

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        name={name}
        control={control}
        rules={validationRules}
        render={({ field: { onBlur, ...props } }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <FormControl>
              <Input
                placeholder={placeholder}
                {...props}
                onBlur={(e) => handleBlur({ onBlur, value: e.target.value })}
                ref={ref}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default EmailField;
