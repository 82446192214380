import { useFormMetadataContext } from "@/providers";

interface StepperProps {
  currentStep: number;
}

const Stepper = ({ currentStep }: StepperProps) => {
  const { uiSchema } = useFormMetadataContext();
  const steps = uiSchema!.pages;

  return (
    <nav aria-label="Progress">
      <div className="fr-mb-4">
        <h2 className="fr-text-xl fr-font-semibold fr-text-[24px]">
          {steps[currentStep].title}
        </h2>
      </div>
      <div className="fr-pt-1">
        <div className="fr-relative fr-flex">
          <div className="fr-h-[6px] fr-flex fr-rounded fr-bg-gray-200 fr-w-full" />
          <div
            style={{ width: `${((currentStep + 1) / steps.length) * 100}%` }}
            className="fr-absolute fr-translate-y-[-25%] fr-left-0 fr-h-[12px] fr-flex fr-rounded fr-bg-light-blue-300"
          />
        </div>
        <div className="fr-flex fr-text-sm fr-mt-2">
          <span className="fr-text-light-blue-300">Step {currentStep + 1}</span>
          <span className="fr-text-gray-400 fr-ml-1"> of {steps.length}</span>
        </div>
      </div>
    </nav>
  );
};

export { Stepper };
