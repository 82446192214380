"use client";

import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";
import { Check } from "lucide-react";

import { cn } from "@/lib/utils";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("fr-gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "fr-aspect-square fr-mt-2 -fr-mr-1 fr-h-[24px] fr-w-[24px] fr-rounded-[8px] fr-border fr-border-input fr-text-primary fr-ring-offset-background focus:fr-outline-none focus-visible:ring-2 focus-visible:fr-ring-ring focus-visible:fr-ring-offset-2 disabled:fr-cursor-not-allowed disabled:fr-opacity-50 data-[state=checked]:fr-bg-light-blue-100",
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="fr-flex fr-items-center fr-justify-center">
        <Check className="fr-h-4 fr-w-4 fr-stroke-[3px] fr-text-light-blue-300" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

export { RadioGroup, RadioGroupItem };
