const SUPPORTED_FIELDS = ["input", "switch", "select", "date"];

const defaultValues = {
  backgroundCondition: "3", // none of the above
  hadBorderCrossingCard: false,
  hadTmpResidentVisa: false,
  hasTmpResidentVisa: false,
  hasMultipleNationalities: false,
  arrivalKnown: false,
  passportType: "0", // default passport
  arrivalType: "2", // unknown
};

function getDefaultValuesFromUISchema(uiFormSchemaSteps: any) {
  const extractFields = (nodes: any) => {
    return nodes.reduce((acc: any, node: any) => {
      if (node.type === "container" && node.nodes) {
        return { ...acc, ...extractFields(node.nodes) };
      }

      if (SUPPORTED_FIELDS.includes(node.type)) {
        return { ...acc, [node.name]: "" };
      }

      return acc;
    }, defaultValues);
  };

  return uiFormSchemaSteps.pages.reduce((fieldNames: any, step: any) => {
    return {
      ...fieldNames,
      ...extractFields(step.nodes),
    };
  }, {});
}

export { getDefaultValuesFromUISchema };
