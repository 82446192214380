import type { FormContentNode } from "@/types/node.ts";
import { GridCol } from "@/components/Grid/Col.tsx";
import Markdown from "@/components/Markdown/Markdown.tsx";

type ContentProps = FormContentNode & {
  gridCol: number;
};

const Content = ({ content, gridCol }: ContentProps) => {
  return (
    <GridCol span={gridCol}>
      <Markdown content={content} />
    </GridCol>
  );
};

export default Content;
