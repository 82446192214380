import type { FormSelectNode } from "@/types/node.ts";
import { useApplicantContext } from "@/providers";
import { useFormContext } from "react-hook-form";
import { useApplicantMutations } from "@/hooks";
import { GridCol } from "@/components/Grid/Col.tsx";
import { FormField, FormItem, FormLabel } from "@/components/Form/Form.tsx";
import {
  MultiSelect,
  MultiSelectContent,
  MultiSelectInput,
  MultiSelectItem,
  MultiSelectList,
  MultiSelectTrigger,
} from "@/components/MultiSelect/MultiSelect.tsx";
import * as React from "react";
import { useFieldRules } from "@/hooks/useFieldRules/useFieldRules.ts";
import { useFieldOptions } from "@/hooks/useFieldOptions/useFieldOptions.ts";
import Flag from "@/components/Flag/Flag.tsx";

type HandleChangeProps = {
  name: string;
  value: string[];
  onChange: (value: string[]) => void;
};

const MultiNationalitiesField = ({
  name,
  label,
  description,
  placeholder,
  visibleIf,
  visibleIfOp,
  options,
  gridCol,
  rules,
  tooltip,
  emptyMessage,
}: FormSelectNode & { gridCol?: number }) => {
  const { applicantToken } = useApplicantContext();
  const { control, clearErrors, watch } = useFormContext();
  const { validationRules, isVisible } = useFieldRules({
    fieldName: name,
    rules,
    visibleIf,
    visibleIfOp,
    emptyMessage,
  });
  const passportNationalityCode = watch("passportNationalityCode");
  const { fieldOptions } = useFieldOptions({ name, options });
  const { updateTemporaryApplicantMutation } = useApplicantMutations();

  if (!isVisible) return null;

  const handleChange = ({ name, value, onChange }: HandleChangeProps) => {
    onChange(value);
    if (applicantToken) {
      updateTemporaryApplicantMutation.mutate({
        field: name,
        value,
      });
    }

    clearErrors(name);
  };

  const filteredFieldOptions = fieldOptions.filter(
    (o: any) =>
      passportNationalityCode &&
      o.value.toString() !== passportNationalityCode.toString(),
  );

  return (
    <GridCol span={gridCol} sm={12}>
      <FormField
        control={control}
        rules={validationRules}
        name={name}
        render={({ field }) => (
          <FormItem>
            <FormLabel description={description} tooltip={tooltip}>
              {label}
            </FormLabel>
            <MultiSelect
              onValuesChange={(value) =>
                handleChange({ name, value, onChange: field.onChange })
              }
              values={field.value}
            >
              <MultiSelectTrigger>
                <MultiSelectInput placeholder={placeholder} />
              </MultiSelectTrigger>
              <MultiSelectContent>
                <MultiSelectList>
                  {filteredFieldOptions?.map((option: any) => (
                    <MultiSelectItem key={option.value} value={option.label}>
                      <div className="fr-flex fr-space-x-2">
                        <Flag alt={option.label} countryCode={option.code} />
                        <p className="fr-mb-0">{option.label}</p>
                      </div>
                    </MultiSelectItem>
                  ))}
                </MultiSelectList>
              </MultiSelectContent>
            </MultiSelect>
          </FormItem>
        )}
      />
    </GridCol>
  );
};

export default MultiNationalitiesField;
