import { useFormMetadataContext } from "@/providers";
import Fields from "../Fields/Fields";
import React from "react";
import { Grid } from "@/components/Grid/Grid.tsx";

type PageProps = {
  currentStep: number;
};

const Page = ({ currentStep }: PageProps) => {
  const { uiSchema } = useFormMetadataContext();

  return uiSchema?.pages[currentStep].nodes.map((node, i) => (
    <Grid key={node.name}>
      <Fields key={node.name} node={node} />
    </Grid>
  ));
};

export default Page;
