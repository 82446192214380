import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import type { FormCondition, FormConditionOp } from "@/types/condition.ts";
import { getConditionValue } from "@/lib/utils";
import { useQuery } from "@tanstack/react-query";

type UseConditionalRenderingProps = {
  visibleIf?: FormCondition[];
  visibleIfOp?: FormConditionOp;
  isPassportCodeAndNationalityMismatch?: boolean;
};

export function useConditionalRendering({
  visibleIf,
  visibleIfOp,
  isPassportCodeAndNationalityMismatch,
}: UseConditionalRenderingProps) {
  const [isVisible, setIsVisible] = useState(false);
  const { watch, getValues } = useFormContext();

  // TODO: remove this garbage once we have a properly written backend
  const { data: formSchemaData } = useQuery({ queryKey: ["formData"] });
  // TODO: remove this garbage once we have a properly written backend
  const restrictedCountries = [
    6, 8, 25, 44, 92, 102, 109, 116, 121, 148, 158, 179, 184, 189, 193, 199,
  ];

  const arrayMethod =
    visibleIfOp === "and" || (visibleIfOp === "or" && visibleIf?.length === 0)
      ? "every"
      : "some";
  /**
   * Check if the field should be visible when we load the form step
   */
  useEffect(() => {
    const isVisible = visibleIf
      ?.map(({ selector, type, value, values }: any) =>
        getConditionValue({
          data: getValues(),
          type,
          selector,
          value,
          values,
        }),
      )
      [arrayMethod](Boolean);

    // TODO: remove this garbage once we have a properly written backend
    if (isPassportCodeAndNationalityMismatch) {
      const passportNationalityCode = getValues("passportNationalityCode");
      const passportNationality = getValues("passportNationality");
      // @ts-ignore
      const schemaNationalities = formSchemaData?.nationalities;
      // @ts-ignore
      const schemaCountries = formSchemaData?.countries;
      const nationalityLabel =
        schemaNationalities?.[passportNationality]?.label;
      const countryLabel = schemaCountries?.find(
        (c: any) => c.value === passportNationalityCode?.toString(),
      )?.label;
      const countryEquivalentToNationality = schemaCountries?.find((c: any) =>
        c?.label?.includes(nationalityLabel),
      )?.label;
      if (
        restrictedCountries.includes(Number(passportNationalityCode)) &&
        restrictedCountries.includes(Number(passportNationality)) &&
        countryLabel === countryEquivalentToNationality
      ) {
        return setIsVisible(isVisible ?? false);
      }

      if (
        restrictedCountries.includes(Number(passportNationalityCode)) ||
        restrictedCountries.includes(Number(passportNationality))
      ) {
        return setIsVisible(true);
      }

      return setIsVisible(false);
    }

    setIsVisible(isVisible ?? true);
  }, []);

  /**
   * Subscribe to step changes and change visibility appropriately
   */
  useEffect(() => {
    if (
      !isPassportCodeAndNationalityMismatch &&
      (visibleIf?.length === 0 || !visibleIf)
    ) {
      setIsVisible(true);
    }

    const subscribe = watch((data) => {
      const isVisible = visibleIf
        ?.map(({ selector, type, value, values }: any) =>
          getConditionValue({ data, type, selector, value, values }),
        )
        [arrayMethod](Boolean);

      // TODO: remove this garbage once we have a properly written backend
      if (isPassportCodeAndNationalityMismatch) {
        // @ts-ignore
        const schemaNationalities = formSchemaData?.nationalities;
        // @ts-ignore
        const schemaCountries = formSchemaData?.countries;
        const nationalityLabel =
          schemaNationalities?.[data?.passportNationality]?.label;
        const countryLabel = schemaCountries?.find(
          (c: any) => c.value === data?.passportNationalityCode?.toString(),
        )?.label;
        const countryEquivalentToNationality = schemaCountries?.find((c: any) =>
          c?.label?.includes(nationalityLabel),
        )?.label;

        if (
          restrictedCountries.includes(Number(data?.passportNationalityCode)) &&
          restrictedCountries.includes(Number(data?.passportNationality)) &&
          countryLabel === countryEquivalentToNationality
        ) {
          return setIsVisible(isVisible ?? false);
        }

        if (
          restrictedCountries.includes(Number(data?.passportNationalityCode)) ||
          restrictedCountries.includes(Number(data?.passportNationality))
        ) {
          return setIsVisible(true);
        }

        return setIsVisible(false);
      }

      setIsVisible(isVisible ?? true);
    });

    return () => subscribe.unsubscribe();
  }, [watch, visibleIf]);

  return {
    isVisible,
  };
}
